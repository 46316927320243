import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_1_day_timeslot_columns from '../../../../../../../common/src/assets/image/example_1_day_timeslot_columns.png';
import template_options_1_day_with_timeslot_columns from '../../../../../../../common/src/assets/image/template_options_1_day_with_timeslot_columns.png';

const Documentation = () => {
  const content = (
    <div id="help">
      <p>
        This template generates a calendar showing 1 day per page. Individual
        calendars will be shown as rows and timeslots will be shown as columns.
      </p>
      <p>
        <Image alt="" src={example_1_day_timeslot_columns} />
      </p>
      <h2>Template Settings</h2>
      <p>
        When creating a calendar using the 1 Day/Page with Timeslot Columns
        template, the following options are provided...
      </p>
      <Image
        alt=""
        src={template_options_1_day_with_timeslot_columns}
        style={{ width: 446 }}
      />
      <br />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start date</strong>
            </td>
            <td>
              The start date can be typed into the box or selected using the
              drop-down arrow. This date will be the left-most column in the
              generated calendar.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Number of days</strong>
            </td>
            <td>
              The total number of days to include in the output. Each day will
              generate to its own worksheet or page.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Timeslots</strong>{' '}
            </td>
            <td>
              A timeslot range and interval can be specified. The output will
              only show times between the specified start and end values. Each
              row will correspond to the specified timeslot length, such as 30
              minutes.
            </td>
          </tr>
          <tr>
            <td>
              <strong>T</strong>
              <strong>ime Format</strong>
            </td>
            <td>
              <span>The time format. Supported formats are </span>
              <strong>12-hour</strong>
              <span> (8:00 PM), </span>
              <strong>24-hour</strong>
              <span> (20:00), </span>
              <strong>Shortened</strong>
              <span> (8pm), </span>
              <strong>Military </strong>
              <span>(2000), and </span>
              <strong>Numeric </strong>
              <span>
                (20.0). An example time is shown for each format in the
                drop-down list.
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Rows per calendar</strong>{' '}
            </td>
            <td>
              The number of rows for each calendar data source. This value will
              limit how many overlapping items can be shown for a single
              calendar data source. If you have many overlapping items, make
              sure to set this value large enough for all items to fit.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Text behavior</strong>
            </td>
            <td>
              The text behavior of items. Options are <strong>Wrap text</strong>
              , <strong>Don't wrap text</strong>, and{' '}
              <strong>Shrink text to fit</strong>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item font size</strong>{' '}
            </td>
            <td>
              Override the default font size associated with each calendar data
              source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Items fill available space</strong>{' '}
            </td>
            <td>
              If the <strong>Rows per calendar</strong> value is large enough
              that empty rows would appear for a calendar data source, this
              option (when checked) will expand items to fill the available
              space, rather than showing white space.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show start time </strong>
            </td>
            <td>
              When checked, item start times will be shown. The ordering of
              times, title, and location values can be specified in the{' '}
              <Link to="/Documentation/Options">PrintableCal options</Link>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show location</strong>{' '}
            </td>
            <td>When checked, item locations will be shown.</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page-with-Timeslot-Columns"
      commentsId="commentsplus_post_1188_1628"
      title="1 Day/Page with Timeslot Columns Template"
      description="Generate a calendar showing 1 day per page. Individual calendars will be shown as rows and timeslots will be shown as columns."
      keywords="day template, calendar rows, timeslot columns"
      content={content}
    />
  );
};

export default Documentation;
